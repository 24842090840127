import {toast} from "react-toastify";
import {AddressType, ITokenInfo, SelectedCurrencyType} from "../../pages/ConsolidationTool/types";
import {Tooltip} from "react-tooltip";

interface TokenInfoProps {
  dataToken: ITokenInfo | null | undefined,
  currency: SelectedCurrencyType
}

export const TokenInfo = ({
                            dataToken,
                            currency
                          }: TokenInfoProps) => {

  if (!dataToken) return null

  const hideAddress = (address: AddressType) => {
    return address.substring(0, 5) + '...' + address.substring(address.length - 5)
  }

  const {address, symbol, title, decimal} = dataToken
  return (
    <Tooltip
      anchorSelect={`#token-info-tooltip-${currency.toString().replaceAll(/[\W_]+/g,"")}`}
      clickable
      place="top"
    >
      <div className="mt-5 mb-5 grid grid-cols-2 gap-3 border border-solid border-blueGray-300 rounded-2xl p-5">
        <div>
          <h4 className="h5-title text-blueGray-300">Name:</h4>
          <div className="text-base">{title}</div>
        </div>

        {address ? <div>
          <h4 className="h5-title text-blueGray-300">Contract address:</h4>
          <div
            className="text-base break-all hover:opacity-70 transition-all cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(address)
              toast.success('Copied: ' + address)
            }}
          >
            {hideAddress(address)} <i className="icon-copy"/>
          </div>
        </div> : <div></div>}

        <div>
          <h4 className="h5-title text-blueGray-300">Symbol:</h4>
          <div className="text-base">{symbol}</div>
        </div>

        <div>
          <h4 className="h5-title text-blueGray-300">Decimal:</h4>
          <div className="text-base">{decimal}</div>
        </div>
      </div>
    </Tooltip>
  );
};
