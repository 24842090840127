import React, {useEffect, useMemo, useState} from "react";
import * as Sentry from "@sentry/react";
import {IWeb3Facade} from "../facades/IWeb3Facade";
import {IConsolidationToolView} from "../Views/ConsolidationToolView";
import {
  AddressType,
  evmNetworks, FeeDataByCurrencyAndAddress,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IMapValueByAddress,
  ITokenDict,
  ITransactionPriorityEnum,
  NetworkCurrencyEnum,
  NetworkTitleEnum,
  PrivateKeyType,
  ReceiverAddressType, SelectedCurrencyType,
  StatusTxEnum, BalanceDataByCurrencyAndAddress, TransactionsDataByCurrencyAndAddress, TxHashByCurrencyAndAddress
} from "../types";
import {CheckInfo, TransactionInfo} from "../../../components/Table";
import {IWeb3TokenFacade} from "../facades/IWeb3TokenFacade";
import {fromUnitToToken, GasHelper, partitionArrayIntoChunks, sendRequestDelay} from "../../../helpers";
import {RootState} from "../../../store";
import {generalTransferInfoActions} from "../../../store/common/generalTransferInfo.slice";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getSortedBalances} from "../../../helpers/sortData";
import {ETHFacade} from "../facades/ETH_Network/ETHFacade";
import {DISPERSE_FACADES} from "../../DisperseTool/factory";
import {partitionMapIntoChunks} from "../../../helpers/toChunks";

function isWeb3TokenFacade(facadeByCurrency: IWeb3Facade | IWeb3TokenFacade): facadeByCurrency is IWeb3TokenFacade {
  return typeof facadeByCurrency.fetchTokenInfo === "function"
}

function withFacade(WrappedComponent: React.FC<IConsolidationToolView>, facadeByCurrency: IWeb3Facade | IWeb3TokenFacade) {
  const isDevEnv = process.env.REACT_APP_ENVIRONMENT === "dev";
  const isMultiToken = evmNetworks.includes(facadeByCurrency.network)

  return () => {
    const networkCurrency = useMemo(() => {
      return NetworkCurrencyEnum[facadeByCurrency.network]
    }, [facadeByCurrency]);

    const isAllowDisperse = useMemo(() => {
      return DISPERSE_FACADES.hasOwnProperty(facadeByCurrency.network)
    }, [facadeByCurrency]);

    const [selectedCurrencies, setSelectedCurrencies] = useState<Set<SelectedCurrencyType>>(new Set([networkCurrency]))
    const [selectedCurrencyError, setSelectedCurrencyError] = useState<string | null>(null)
    const [selectedCurrencyIsLoading, setSelectedCurrencyIsLoading] = useState<boolean>(false)
    const [tokenDict, setTokenDict] = useState<ITokenDict>(facadeByCurrency.tokensDict)

    const [receiverAddress, setReceiverAddress] = useState<ReceiverAddressType | null>(null)
    const [privateKeysError, setPrivateKeysError] = useState<string | null>(null)
    const [privateKeysMessage, setPrivateKeysMessage] = useState<string | null>(null)
    const [estimateError, setEstimateError] = useState<string | null>(null)
    const [privateKeyByAddress, setPrivateKeyByAddress] = useState<IMapValueByAddress<PrivateKeyType>>(new Map())
    const [isValidatingPrivateKeys, setIsValidatingPrivateKeys] = useState<boolean>(false)
    const [transactionDataByCurrencyAndAddress, setTransactionDataByCurrencyAndAddress] = useState<TransactionsDataByCurrencyAndAddress>(new Map())
    const [currentTransactionPriority, setCurrentTransactionPriority] = useState<keyof ITransactionPriorityEnum>(facadeByCurrency.defaultTransactionPriority)

    const [balanceDataByCurrencyAndAddress, setBalanceDataByCurrencyAndAddress] = useState<BalanceDataByCurrencyAndAddress>(new Map())

    const [feeDataByCurrencyAndAddress, setFeeDataByCurrencyAndAddress] = useState<FeeDataByCurrencyAndAddress>(new Map())
    const [isLoadingEstimate, setIsLoadingEstimate] = useState<boolean>(false)

    const [txHashByCurrencyAndAddress, setTxHashByCurrencyAndAddress] = useState<TxHashByCurrencyAndAddress>(new Map())
    const [isProcessingSend, setIsProcessingSend] = useState<boolean>(false)
    const [isSuccessSend, setIsSuccessSend] = useState<true | undefined>()
    const [errorSend, setErrorSend] = useState<Error | undefined>()

    const dispatch = useAppDispatch()
    const previousNetwork = useAppSelector((state: RootState) => state.generalTransferInfo.network)
    const previousReceiverAddress = useAppSelector((state: RootState) => state.generalTransferInfo.receiverAddress)

    useEffect(() => {
      const currentNetwork = facadeByCurrency.network

      if (previousNetwork) {
        if (!evmNetworks.includes(previousNetwork) || !evmNetworks.includes(currentNetwork)) {
          dispatch(generalTransferInfoActions.resetPrivateKeys())
          dispatch(generalTransferInfoActions.resetReceiverAddress())
        }
      }
      if (previousReceiverAddress && evmNetworks.includes(currentNetwork)) {
        setReceiverAddress(previousReceiverAddress)
      }

      dispatch(generalTransferInfoActions.setPrivateKeysNetwork(currentNetwork))
    }, [])

    useEffect(() => {
      if (facadeByCurrency instanceof ETHFacade) {
        dispatch(generalTransferInfoActions.setReceiverAddress(receiverAddress || ''))
      }
    }, [receiverAddress])

    const receiverAddressError = useMemo(() => {
      if (!receiverAddress?.length || facadeByCurrency.validateAddress(receiverAddress)) return null

      return 'Address is invalid!'
    }, [receiverAddress]);


    const isSendingBaseCurrency = useMemo(() => {
      return selectedCurrencies.has(networkCurrency)
    }, [selectedCurrencies])


    const tableRows: TransactionInfo[] | CheckInfo [] = useMemo(() => {
      const resultTableData: CheckInfo | TransactionInfo[] = []

      privateKeyByAddress.forEach((privateKey, address) => {
        const tokenBalances: Map<SelectedCurrencyType, string> = new Map()
        const feeDataByCurrency: Map<SelectedCurrencyType, string> = new Map()
        const txHashDataByCurrency: Map<SelectedCurrencyType, string> = new Map()

        balanceDataByCurrencyAndAddress.forEach((balanceData, token) => {
          tokenBalances.set(token, convertToToken(token, balanceData.get(address) || BigInt(0)))
        })
        feeDataByCurrencyAndAddress.forEach((feeData, currency) => {
          feeDataByCurrency.set(currency, facadeByCurrency.toBaseCurrencyFromUnit(feeData.get(address) || BigInt(0)))
        })
        txHashByCurrencyAndAddress.forEach((txHashData, currency) => {
          if (txHashData.has(address)) {
            txHashDataByCurrency.set(currency, txHashData.get(address))
          }
        })
        const baseCurrencyBalanceData = balanceDataByCurrencyAndAddress.get(networkCurrency) || new Map()

        resultTableData.push({
          status: txHashDataByCurrency.size ? StatusTxEnum.SUCCESS : StatusTxEnum.SKIP,
          address: address,
          privateKey: privateKey,
          balance: baseCurrencyBalanceData.has(address) ? facadeByCurrency.toBaseCurrencyFromUnit(baseCurrencyBalanceData.get(address)!) : "0",
          tokenBalances,
          feeDataByCurrency,
          txHashDataByCurrency
        })
      })
      return resultTableData
    }, [balanceDataByCurrencyAndAddress, privateKeyByAddress, feeDataByCurrencyAndAddress, txHashByCurrencyAndAddress]);

    const recipientsAndValuesForCharge = useMemo(() => {
      const _recipientsAndValues: IMapValueByAddress<bigint> = new Map()
      const baseCurrencyBalanceData = balanceDataByCurrencyAndAddress.get(networkCurrency)!

      if (isSendingBaseCurrency) return _recipientsAndValues

      for (const address of baseCurrencyBalanceData.keys()) {
        const balance = baseCurrencyBalanceData.get(address)!
        let fee = BigInt(0)
        feeDataByCurrencyAndAddress.forEach(feeData => {
          fee += feeData.get(address) || BigInt(0)
        })

        if (balance < fee) {
          _recipientsAndValues.set(address, GasHelper.gasPricePlusPercent(fee, 20) - balance)
        }
      }
      return _recipientsAndValues
    }, [feeDataByCurrencyAndAddress])

    const isDisabledEstimate = !!(!receiverAddress || receiverAddressError || privateKeysError || !privateKeyByAddress.size || privateKeyByAddress.size > facadeByCurrency.getLimitPrivateKeys())
    const isDisabledCharge = !isAllowDisperse || isDisabledEstimate || estimateError || privateKeyByAddress.size > facadeByCurrency.getLimitPrivateKeys()
    const isDisabledSend = isDisabledEstimate || recipientsAndValuesForCharge.size || estimateError || !(transactionDataByCurrencyAndAddress.size > 0)

    function getTokenAddress(token: SelectedCurrencyType) {
      return tokenDict[token].address!;
    }

    function convertToToken(token: SelectedCurrencyType, amount: bigint) {
      return fromUnitToToken(tokenDict[token].decimal)(amount)
    }

    async function toAddress(privateKeys: Set<PrivateKeyType>) {
      const _accountsAndKeys: IMapValueByAddress = new Map()
      setPrivateKeysError(null)
      setIsValidatingPrivateKeys(true)

      console.log('Parsing...')

      setTimeout(() => {
        privateKeys.forEach(function (key) {
          try {
            const {address, privateKey} = facadeByCurrency.privateKeyToAccount(key)
            _accountsAndKeys.set(address, privateKey)
          } catch (error: any) {
            Sentry.captureException(error, {
              tags: {
                section: "consolidation",
                method: "privateKeyToAccount"
              },
              contexts: {
                "__getOrCreateAssociatedTokenAccount": {
                  network: facadeByCurrency.network,
                  currency: NetworkCurrencyEnum[facadeByCurrency.network],
                  count_keys: privateKeys.size,
                }
              }
            });
            setPrivateKeysError(error?.message || `Private key ${key} is invalid`)
          }
        })
        setPrivateKeyByAddress(_accountsAndKeys)
        console.log('Parsed')
        setIsValidatingPrivateKeys(false)
        if (_accountsAndKeys.size > facadeByCurrency.getLimitPrivateKeys()) {
          setPrivateKeysError(`You entered more private keys than specified in the limit. The limit is ${facadeByCurrency.getLimitPrivateKeys()}.`)
          return
        }

        setPrivateKeysMessage("Converted successfully")
        setTimeout(() => setPrivateKeysMessage(null), 2000)

        if (!(facadeByCurrency instanceof ETHFacade)) {
          dispatch(generalTransferInfoActions.resetPrivateKeys())
        }
      }, 0)
    }


    function __fetchTokenData(addressInput: AddressType) {
      if (!facadeByCurrency.fetchTokenInfo) return

      setSelectedCurrencyIsLoading(true)
      facadeByCurrency.fetchTokenInfo(addressInput)
        .then(tokenData => {
          const _tokenDict = {...tokenDict}
          _tokenDict[tokenData.symbol] = {..._tokenDict[tokenData.symbol] ?? {}, ...tokenData}
          setTokenDict(_tokenDict)
          __addSelectedToken(tokenData.symbol)
        })
        .catch(error => {
          Sentry.captureException(error, {
            tags: {
              section: "consolidation",
              method: "fetchTokenInfo"
            },
            contexts: {
              "__fetchTokenData": {
                network: facadeByCurrency.network,
                currency: NetworkCurrencyEnum[facadeByCurrency.network],
                count_keys: privateKeyByAddress.size,
                address_input: addressInput
              }
            }
          });
          console.error('__fetchTokenData.fetchTokenInfo=>', error?.message)
          setSelectedCurrencyError(error?.message)
        })
        .finally(() => {
          setSelectedCurrencyIsLoading(false)
        })
    }


    function __addSelectedToken(tokenSymbol: string) {
      if (isSendingBaseCurrency || !isMultiToken) {
        const newSelectedCurrencies = new Set<SelectedCurrencyType>()
        newSelectedCurrencies.add(tokenSymbol)
        setSelectedCurrencies(newSelectedCurrencies)
      } else {
        selectedCurrencies.add(tokenSymbol)
        setSelectedCurrencies(selectedCurrencies)
      }
    }


    function handleSelectCurrency(currencies: Set<SelectedCurrencyType>) {
      if (isMultiToken) {
        if (currencies.has(networkCurrency) && !isSendingBaseCurrency) {
          const newSelectedCurrencies = new Set<SelectedCurrencyType>()
          newSelectedCurrencies.add(networkCurrency)
          setSelectedCurrencies(newSelectedCurrencies)
        } else if (currencies.size < selectedCurrencies.size) {
          setSelectedCurrencies(currencies)
        } else {
          currencies.forEach(currency => {
            if (tokenDict.hasOwnProperty(currency) && currency !== networkCurrency && !selectedCurrencies.has(currency)) {
              __fetchTokenData(tokenDict[currency].address!)
            }
          })
        }
      } else {
        if (!currencies.has(networkCurrency)) {
          currencies.forEach(currency => {
            if (tokenDict.hasOwnProperty(currency) && currency !== networkCurrency && !selectedCurrencies.has(currency)) {
              __fetchTokenData(tokenDict[currency].address!)
            }
          })
        } else {
          setSelectedCurrencies(currencies)
        }
      }

      setSelectedCurrencyError(null)
    }


    function handleAddCurrency(addressInput: AddressType) {
      if (!facadeByCurrency.validateAddress(addressInput)) {
        setSelectedCurrencyError('Address is invalid!')
        return
      }
      setSelectedCurrencyError(null)
      __fetchTokenData(addressInput)
    }


    async function _fetchBalance(): Promise<BalanceDataByCurrencyAndAddress> {
      let _balanceDataByCurrencyAndAddress: BalanceDataByCurrencyAndAddress = new Map()
      let _balanceDataForSorting: Map<SelectedCurrencyType, IMapValueByAddress<number>> = new Map()

      const chunkSize: number = facadeByCurrency.getAddressesChunkSize();
      const promises: Promise<boolean>[] = [];
      let counter: number = 0;

      for (const chunk of partitionArrayIntoChunks(Array.from(privateKeyByAddress.keys()), chunkSize)) {
        const addressList = new Set<AddressType>(chunk)

        const baseCurrencyReq = () => facadeByCurrency.fetchBaseCurrencyBalanceDataByAddress(addressList)
          .then(baseCurrencyBalanceDataChunk => {
            const currentBalanceData = _balanceDataByCurrencyAndAddress.get(networkCurrency) || new Map()
            _balanceDataByCurrencyAndAddress.set(networkCurrency, new Map([...currentBalanceData, ...baseCurrencyBalanceDataChunk]))

            const currentBalanceDataForSorting = _balanceDataForSorting.get(networkCurrency) || new Map()
            baseCurrencyBalanceDataChunk.forEach((balance, address) => {
              currentBalanceDataForSorting.set(address, +facadeByCurrency.toBaseCurrencyFromUnit(balance))
            })
            _balanceDataForSorting.set(networkCurrency, currentBalanceDataForSorting)
            return true;
          })
          .catch(error => {
            Sentry.captureException(error, {
                tags: {
                  section: "consolidation",
                  method: "fetchBaseCurrencyBalanceDataByAddress"
                },
                contexts: {
                  "_fetchBalance": {
                    network: facadeByCurrency.network,
                    currency: NetworkCurrencyEnum[facadeByCurrency.network],
                    count_keys: privateKeyByAddress.size,
                    chunk_size: chunkSize
                  }
                }
              },
            );
            setIsLoadingEstimate(false)
            setPrivateKeysError(error?.message)
            console.error(`${facadeByCurrency.constructor.name} -> fetchBaseCurrencyBalanceDataByAddress=>`, error)
            return false;
          })
        promises.push(isDevEnv ?
          sendRequestDelay(baseCurrencyReq, facadeByCurrency.getTimeout() * counter)
          : baseCurrencyReq()
        )
        counter++;

        if (!isSendingBaseCurrency && facadeByCurrency.fetchTokenBalanceDataByAddress) {
          for (const token of selectedCurrencies) {
            const tokenReq = () => facadeByCurrency.fetchTokenBalanceDataByAddress(addressList, getTokenAddress(token))
              .then((tokenBalanceDataChunk) => {
                const currentTokenBalanceData = _balanceDataByCurrencyAndAddress.get(token) || new Map()
                _balanceDataByCurrencyAndAddress.set(token, new Map([...currentTokenBalanceData, ...tokenBalanceDataChunk]))

                const currentBalanceDataForSorting = _balanceDataForSorting.get(token) || new Map()
                tokenBalanceDataChunk.forEach((balance, address) => {
                  currentBalanceDataForSorting.set(address, +convertToToken(token, balance))
                })
                _balanceDataForSorting.set(token, currentBalanceDataForSorting)
                return true;
              })
              .catch(error => {
                Sentry.captureException(error, {
                    tags: {
                      section: "consolidation",
                      method: "fetchTokenBalanceDataByAddress"
                    },
                    contexts: {
                      "_fetchBalance": {
                        network: facadeByCurrency.network,
                        currency: NetworkCurrencyEnum[facadeByCurrency.network],
                        count_keys: privateKeyByAddress.size,
                        chunk_size: chunkSize,
                        token_address: getTokenAddress(token)
                      }
                    }
                  },
                );
                setIsLoadingEstimate(false)
                setPrivateKeysError(error?.message)
                console.error(`${facadeByCurrency.constructor.name} -> fetchTokenBalanceDataByAddress=>`, error)
                return false;
              })
            promises.push(isDevEnv ?
              sendRequestDelay(tokenReq, facadeByCurrency.getTimeout() * counter)
              : tokenReq()
            )
            counter++;
          }
        }
      }

      await Promise.all(promises);

      const _sortedPrivateKeys: IMapValueByAddress = new Map()
      const _sortedBalances = getSortedBalances(privateKeyByAddress, _balanceDataForSorting)

      _sortedBalances.forEach((value, address) => _sortedPrivateKeys.set(address, privateKeyByAddress.get(address)!))
      privateKeyByAddress.forEach((privateKey, address) => _sortedPrivateKeys.set(address, privateKey))
      setBalanceDataByCurrencyAndAddress(_balanceDataByCurrencyAndAddress)
      setPrivateKeyByAddress(_sortedPrivateKeys)
      return _balanceDataByCurrencyAndAddress
    }


    async function handleEstimate() {
      if (isDisabledEstimate) return
      setErrorSend(undefined)
      setEstimateError(null)
      setIsLoadingEstimate(true)
      facadeByCurrency.resetGasPriceAndNonce()

      const _balanceDataByCurrencyAndAddress = await _fetchBalance()
      /**
       * For case if smth wrong in facade, or developer made mistake did not return value
       */
      if (!_balanceDataByCurrencyAndAddress) return

      let currentFeeDataByCurrencyAndAddress: FeeDataByCurrencyAndAddress = new Map()
      let currentTransactionDataByCurrencyAndAddress: TransactionsDataByCurrencyAndAddress = new Map()

      let counter: number = 0;
      const promises: Promise<boolean>[] = [];
      const chunkSize: number = facadeByCurrency.getAddressesChunkSize();
      const baseCurrencyBalanceData = _balanceDataByCurrencyAndAddress.get(networkCurrency)!;

      for (const baseCurrencyChunk of partitionMapIntoChunks(baseCurrencyBalanceData, chunkSize)) {
        for (const currency of selectedCurrencies) {
          const currencyChunk = new Map()
          if (!isSendingBaseCurrency) {
            baseCurrencyChunk.forEach((baseCurrencyBalance: bigint, address: AddressType) => {
              const balanceDataByToken = _balanceDataByCurrencyAndAddress.get(currency) || new Map()
              currencyChunk.set(address, balanceDataByToken.get(address)!);
            });
          }

          const generateTransactionsData: IDataForGenerateTransactions = {
            baseCurrencyBalanceData: baseCurrencyChunk,
            tokenBalanceData: currencyChunk,
            privateKeyByAddress,
            transactionPriority: currentTransactionPriority,
            receiverAddress,
          };

          const generateTransactionsReq = () => facadeByCurrency.generateTransactions(
            generateTransactionsData,
            getTokenAddress(currency)
          )
            .then((result) => {
              const currentCurrencyTxData = currentTransactionDataByCurrencyAndAddress.get(currency) || new Map()
              currentTransactionDataByCurrencyAndAddress.set(currency, new Map([...currentCurrencyTxData, ...result.txDataByAddress]))

              const currentCurrencyFeeData = currentFeeDataByCurrencyAndAddress.get(currency) || new Map()
              currentFeeDataByCurrencyAndAddress.set(currency, new Map([...currentCurrencyFeeData, ...result.feeDataByAddress]))
              return true;
            })
            .catch(error => {
              Sentry.captureException(error, {
                tags: {
                  section: "consolidation",
                  method: "generateTransactions"
                },
                contexts: {
                  "handleEstimate": {
                    network: facadeByCurrency.network,
                    currency: NetworkCurrencyEnum[facadeByCurrency.network],
                    count_keys: baseCurrencyBalanceData.size,
                    count_token_keys: balanceDataByCurrencyAndAddress.size || 0,
                    chunk_size: chunkSize,
                    token_address: getTokenAddress(currency)
                  }
                }
              });
              setIsLoadingEstimate(false);
              setEstimateError(error?.message || error.toString());
              console.error(`${facadeByCurrency.constructor.name} -> generateTransactions=>`, error);
              return false;
            });
          promises.push(isDevEnv ?
            sendRequestDelay(generateTransactionsReq, facadeByCurrency.getTimeout() * counter)
            : generateTransactionsReq()
          )
          counter++;
        }
      }

      await Promise.all(promises);

      setTransactionDataByCurrencyAndAddress(currentTransactionDataByCurrencyAndAddress)
      setFeeDataByCurrencyAndAddress(currentFeeDataByCurrencyAndAddress)
      setIsLoadingEstimate(false)
      facadeByCurrency.resetGasPriceAndNonce();
    }


    async function handleSend() {
      if (isDisabledSend) return

      setIsProcessingSend(true)
      setErrorSend(undefined)
      let errorObj: Error | undefined;

      let counter: number = 0;
      const promises: Promise<boolean>[] = [];
      const resultTxReceiptByAddress: TxHashByCurrencyAndAddress = new Map();
      const chunkSize: number = facadeByCurrency.getAddressesChunkSize();

      for (const [currency, txDataByAddress] of transactionDataByCurrencyAndAddress) {
        for (const txDataByAddressChunk of partitionMapIntoChunks(txDataByAddress, chunkSize)) {
          const sendTransactionsData: IDataForSendTransactions = {
            baseCurrencyBalanceData: balanceDataByCurrencyAndAddress.get(networkCurrency)!,
            privateKeyByAddress,
            transactionDataByAddress: txDataByAddressChunk,
            transactionPriority: currentTransactionPriority,
            receiverAddress,
          }

          const sendTransactionsReq = () => facadeByCurrency.sendTransactions(sendTransactionsData, getTokenAddress(currency))
            .then((result) => {
              const currentTxReceiptData = resultTxReceiptByAddress.get(currency) || new Map()
              resultTxReceiptByAddress.set(currency, new Map([...currentTxReceiptData, ...result]))
              return true;
            })
            .catch(error => {
              Sentry.captureException(error, {
                tags: {
                  section: "consolidation",
                  method: "sendTransactions"
                },
                contexts: {
                  "handleSend": {
                    network: facadeByCurrency.network,
                    currency: NetworkCurrencyEnum[facadeByCurrency.network],
                    count_keys: txDataByAddress.size,
                    token_address: getTokenAddress(currency)
                  }
                }
              });
              errorObj = Error(error?.message || error)
              console.error(`${facadeByCurrency.constructor.name} -> sendTransactions=>`, error)
              setErrorSend(errorObj)
              setIsProcessingSend(false)
              return false;
            });
          promises.push(
            isDevEnv ?
              sendRequestDelay(sendTransactionsReq, facadeByCurrency.getTimeout() * counter)
              : sendTransactionsReq()
          );
          counter++;
        }
      }

      await Promise.all(promises);

      setTxHashByCurrencyAndAddress(resultTxReceiptByAddress || (new Map()))
      setIsSuccessSend(true)
      setIsProcessingSend(false)

      _fetchBalance()

      setIsProcessingSend(false)
    }


    const props = {
      isWeb3TokenFacade: isWeb3TokenFacade(facadeByCurrency),
      networkCurrency: networkCurrency,
      network: facadeByCurrency.network,
      title: NetworkTitleEnum[facadeByCurrency.network],
      isMultiToken: evmNetworks.includes(facadeByCurrency.network),
      tableData: {
        tableRows: tableRows,
        linkForTxScan: facadeByCurrency.linkForTxScan,
      },
      transactionPriority: {
        value: currentTransactionPriority,
        setCurrentTransactionPriority,
        options: facadeByCurrency.transactionPriorityOptions
      },
      receiverAddress: {
        value: receiverAddress,
        setReceiverAddress,
        error: receiverAddressError
      },
      privateKeys: {
        isValidating: isValidatingPrivateKeys,
        error: privateKeysError,
        feedback: privateKeysError || estimateError || privateKeysMessage,
        limit: facadeByCurrency.getLimitPrivateKeys(),
        isLimit: privateKeyByAddress.size > facadeByCurrency.getLimitPrivateKeys(),
        privateKeyByAddress,
        privateKeysToAddresses: toAddress
      },
      estimate: {
        handleEstimate,
        isDisabled: isDisabledEstimate,
        isLoading: isLoadingEstimate,
        error: estimateError,
      },
      charge: {
        recipientsAndValues: recipientsAndValuesForCharge,
        isDisabled: isDisabledCharge
      },
      send: {
        txHashByAddress: txHashByCurrencyAndAddress,
        handleSend,
        isDisabled: isDisabledSend,
        isSuccess: isSuccessSend,
        isProcessing: isProcessingSend,
        error: errorSend ? errorSend.message : null,
      },
      selectedCurrencies: {
        value: selectedCurrencies,
        handleSelectCurrency,
        handleAddCurrency,
        error: selectedCurrencyError,
        isLoading: selectedCurrencyIsLoading,
        tokensDict: tokenDict,
      },
    }

    return (
      <WrappedComponent {...props} />
    );

  }
}

export {withFacade}