import React, {useMemo, useRef} from "react";
import {Block, Button, Input, Loader, Modal, Select, Table, Textarea} from "../../../components";
import {
  AddressType, evmNetworks,
  IMapValueByAddress,
  ITokenDict,
  ITransactionPriorityEnum,
  NetworkCurrencyEnum,
  NetworkType,
  PrivateKeyType, SelectedCurrencyType,
} from "../types";
import {
  createOptionToken,
  customOptionsSelectTokenInit, customSelectMultiValueTokenInit, customSelectSingleValueTokenInit,
  mapOptionsByTokenDict,
  SelectOptionType
} from "../../../helpers/selectOptions";
import {CheckInfo, TransactionInfo} from "../../../components/Table";
import {TransactionPriorityBlock} from "./TransactionPriority";
import {FactoryDisperseTool} from "../../DisperseTool/factory";
import {useModal} from "../../../hooks/useModal";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {generalTransferInfoActions} from "../../../store/common/generalTransferInfo.slice";
import packageInfo from "../../../../package.json";
import {MultiSelect} from "../../../components/ui/MultiSelect";

export interface IConsolidationToolView {
  isWeb3TokenFacade: boolean
  networkCurrency: typeof NetworkCurrencyEnum[NetworkType],
  network: NetworkType,
  title: string,
  isMultiToken: boolean,
  tableData: {
    tableRows: TransactionInfo[] | CheckInfo [],
    linkForTxScan: string,
  },
  transactionPriority: {
    value: keyof ITransactionPriorityEnum,
    setCurrentTransactionPriority(value: keyof ITransactionPriorityEnum): void,
    options: ITransactionPriorityEnum
  },
  receiverAddress: {
    value: AddressType | null,
    setReceiverAddress(address: AddressType): void,
    error: string | null
  },
  privateKeys: {
    privateKeysToAddresses(privateKeys: Set<PrivateKeyType>): void,
    error: string | null,
    feedback: string | null,
    limit: number,
    isLimit: boolean
    isValidating: boolean
    privateKeyByAddress: IMapValueByAddress<PrivateKeyType>,
  },
  estimate: {
    handleEstimate(): void,
    isDisabled: boolean,
    error: string | null
    isLoading: boolean
  },
  charge: {
    recipientsAndValues: IMapValueByAddress<bigint>,
    isDisabled: boolean
  }
  send: {
    txHashByAddress: IMapValueByAddress,
    handleSend(): void,
    isDisabled: boolean,
    isSuccess: true | undefined,
    isProcessing: boolean,
    error: string | null
  },
  selectedCurrencies: {
    tokensDict: ITokenDict,
    value: Set<keyof ITokenDict | string>,
    handleSelectCurrency(currencies: Set<SelectedCurrencyType>): void,
    handleAddCurrency(addressInput: AddressType): void,
    error: string | null
    isLoading: boolean,
  }
}

const ConsolidationToolView: React.FC<IConsolidationToolView> = (props) => {
  const {
    title, selectedCurrencies, receiverAddress,
    privateKeys, tableData, networkCurrency,
    transactionPriority,
    estimate, charge, send, network, isMultiToken
  } = props

  const optionsSelectContract: SelectOptionType[] = useMemo(() => {
    return mapOptionsByTokenDict(selectedCurrencies.tokensDict)
  }, [selectedCurrencies.tokensDict]);

  const CustomSelectSingleValueToken = customSelectSingleValueTokenInit(selectedCurrencies.tokensDict)
  const CustomSelectMultiValueToken = customSelectMultiValueTokenInit(selectedCurrencies.tokensDict)
  const CustomOptionsSelectToken = customOptionsSelectTokenInit(selectedCurrencies.tokensDict)

  const privateKeysRef = useRef<HTMLTextAreaElement>(null);
  const privateKeysCurrentValue = useSelector((state: RootState) => state.generalTransferInfo.privateKeys)
  const receiverAddressCurrentValue = useSelector((state: RootState) => state.generalTransferInfo.receiverAddress)
  const dispatch = useDispatch()

  const {
    isOpenModal,
    closeModal,
    openModal
  } = useModal()

  async function privateKeysToAddresses() {
    if (evmNetworks.includes(network)) {
      dispatch(generalTransferInfoActions.setPrivateKeys(privateKeysRef.current?.value || ""))
    }
    let prk = new Set<PrivateKeyType>(privateKeysRef.current?.value.trim().split("\n"));
    await privateKeys.privateKeysToAddresses(prk);
  }

  function getSelectedCurrenciesValue() {
    const values: Array<{key: string, label: string, value: string}> = [];
    selectedCurrencies.value.forEach(item => values.push(createOptionToken(selectedCurrencies.tokensDict[item])));
    return values;
  }

  function handleSelectCurrency(currencyInput: Array<{label: string, value: string}>) {
    const currencies = new Set<SelectedCurrencyType>(currencyInput.map(item => item.value))

    selectedCurrencies.handleSelectCurrency(currencies)
  }

  return (
    <>
      <p style={{textAlign: 'end'}}>Current Version: {packageInfo.version}</p>
      <div className="grid gap-5">
        <h1 className="h1-title">Consolidation Tool {title}</h1>

        <div className="grid grid-cols-2 gap-5">

          <Block className="h-max">
            <div>
              <label className="label-primary">Select currency</label>

              {
                isMultiToken ? <MultiSelect
                  creatable={props.isWeb3TokenFacade}
                  name="currency"
                  placeholder="Search by Address / Token"
                  value={getSelectedCurrenciesValue()}
                  onChange={(value: any) => handleSelectCurrency(value)}
                  onCreateOption={selectedCurrencies.handleAddCurrency}
                  options={optionsSelectContract}
                  isDisabled={selectedCurrencies.isLoading}
                  isLoading={selectedCurrencies.isLoading}
                  components={{
                    // @ts-ignore
                    MultiValue: CustomSelectMultiValueToken,
                    // @ts-ignore
                    Option: CustomOptionsSelectToken
                  }}
                /> : <Select
                  creatable={props.isWeb3TokenFacade}
                  name="currency"
                  placeholder="Search by Address / Token"
                  value={getSelectedCurrenciesValue()[0]}
                  onChange={(value: any) => handleSelectCurrency([value])}
                  onCreateOption={selectedCurrencies.handleAddCurrency}
                  options={optionsSelectContract}
                  isDisabled={selectedCurrencies.isLoading}
                  isLoading={selectedCurrencies.isLoading}
                  components={{
                    // @ts-ignore
                    SingleValue: CustomSelectSingleValueToken,
                    // @ts-ignore
                    Option: CustomOptionsSelectToken
                  }}
                />
              }
            </div>

            {
              !selectedCurrencies.isLoading ? (
                <div className="mt-5">
                  <div>
                    <label className="label-primary">Receiver (Address)</label>
                    <Input
                      type="text"
                      onChange={(e) => receiverAddress.setReceiverAddress(e.target.value.trim())}
                      placeholder="Enter Receiver address"
                      isInvalid={!!receiverAddress.error}
                      feedback={receiverAddress.error}
                      value={receiverAddressCurrentValue || receiverAddress.value || ''}
                    />
                  </div>

                  <div className="mt-5">
                    <label className="label-primary">
                      <div className="flex justify-between gap-2.5">
                        <span>Send from (Private Keys)</span>

                        <span>
                            <span className={privateKeys.isLimit ? 'text-red-700' : ''}>
                              {privateKeys.privateKeyByAddress.size}</span>/{privateKeys.limit}
                          </span>
                      </div>
                    </label>
                    <Textarea
                      ref={privateKeysRef}
                      rows={4}
                      placeholder="Enter Private keys"
                      isInvalid={!!privateKeys.error || !!estimate.error}
                      feedback={privateKeys.feedback}
                      defaultValue={evmNetworks.includes(network) ? (privateKeysCurrentValue || '') : ''}
                      isValid={!privateKeys.error && !estimate.error && !!privateKeys.feedback}
                    />
                  </div>

                  {privateKeys.isValidating ? (
                    <Block>
                      <Loader text={'Validating addresses...'}/>
                    </Block>
                  ) : null}

                  {
                    receiverAddress.value ? (
                      <div className="flex justify-center gap-10 mt-5">
                        <Button
                          className="btn-primary"
                          onClick={privateKeysToAddresses}
                        >
                          Convert to addresses
                        </Button>
                      </div>
                    ) : null
                  }

                  {
                    !estimate.isDisabled ? (
                      <div className="flex justify-center gap-10 mt-5">
                        <Button
                          className="btn-primary"
                          onClick={estimate.handleEstimate}
                          disabled={estimate.isDisabled}
                        >
                          Estimate
                        </Button>

                        {
                          !send.isDisabled && !estimate.isLoading ? (
                            <Button
                              className="btn-primary-outline"
                              disabled={send.isDisabled}
                              onClick={send.handleSend}
                            >
                              Send
                            </Button>
                          ) : ''
                        }

                        {
                          charge.recipientsAndValues.size ? (
                            <Button
                              className="btn-success"
                              onClick={openModal}
                              disabled={charge.isDisabled}
                            >
                              Charge
                            </Button>
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                </div>
              ) : null
            }
          </Block>

          <TransactionPriorityBlock
            currentPriority={transactionPriority.value}
            optionsPriority={transactionPriority.options}
            onChangePriority={transactionPriority.setCurrentTransactionPriority}
          />
        </div>

        {estimate.isLoading ? (
          <Block>
            <Loader text={'Estimating by addresses...'}/>
          </Block>
        ) : null}
        {send.isProcessing ? (
          <Block>
            <Loader text={'Sending in progress...'}/>
          </Block>
        ) : null}

        {tableData.tableRows.length ? (
          <Block className="!px-0">
            <Table
              listInfo={tableData.tableRows}
              isTransactionInfo={!!send.isSuccess}
              linkForTxScan={tableData.linkForTxScan}
              networkCurrency={networkCurrency}
              selectedCurrencies={selectedCurrencies.value}
              tokenDict={selectedCurrencies.tokensDict}
            />
          </Block>
        ) : ''}
      </div>

      {
        charge.recipientsAndValues.size && !charge.isDisabled ? (
          <Modal
            show={isOpenModal}
            handleClose={closeModal}
            title={'Charge'}
            size="xl"
          >
            <FactoryDisperseTool
              recipientsAndValuesForChargeInUnit={charge.recipientsAndValues}
            />
          </Modal>
        ) : null
      }
    </>
  )
}

export {ConsolidationToolView}